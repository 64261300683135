@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?49036342');
  src: url('../font/fontello.eot?49036342#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?49036342') format('woff2'),
       url('../font/fontello.woff?49036342') format('woff'),
       url('../font/fontello.ttf?49036342') format('truetype'),
       url('../font/fontello.svg?49036342#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?49036342#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-add-friend-tello:before { content: '\e800'; } /* '' */
.icon-bel:before { content: '\e801'; } /* '' */
.icon-home:before { content: '\e802'; } /* '' */
.icon-album:before { content: '\e803'; } /* '' */
.icon-android:before { content: '\e804'; } /* '' */
.icon-anon-status:before { content: '\e805'; } /* '' */
.icon-arrow-direction:before { content: '\e806'; } /* '' */
.icon-snapchat-3:before { content: '\e807'; } /* '' */
.icon-bell-(new):before { content: '\e808'; } /* '' */
.icon-home-(new):before { content: '\e809'; } /* '' */
.icon-copy-2:before { content: '\e80a'; } /* '' */
.icon-send-tell:before { content: '\e80b'; } /* '' */
.icon-unlock-1:before { content: '\e80c'; } /* '' */
.icon-snap:before { content: '\e80d'; } /* '' */
.icon-vector:before { content: '\e80e'; } /* '' */
.icon-arrow-down-big:before { content: '\e80f'; } /* '' */
.icon-arrow-down-small:before { content: '\e810'; } /* '' */
.icon-arrow-left:before { content: '\e811'; } /* '' */
.icon-arrow-right:before { content: '\e812'; } /* '' */
.icon-arrow-up-small:before { content: '\e813'; } /* '' */
.icon-arrow-up:before { content: '\e814'; } /* '' */
.icon-bell:before { content: '\e815'; } /* '' */
.icon-block:before { content: '\e816'; } /* '' */
.icon-calendar:before { content: '\e817'; } /* '' */
.icon-camera:before { content: '\e818'; } /* '' */
.icon-check:before { content: '\e819'; } /* '' */
.icon-close-big:before { content: '\e81a'; } /* '' */
.icon-colorpalette:before { content: '\e81c'; } /* '' */
.icon-comment:before { content: '\e81d'; } /* '' */
.icon-contact-book:before { content: '\e81e'; } /* '' */
.icon-copy-1:before { content: '\e81f'; } /* '' */
.icon-copy:before { content: '\e820'; } /* '' */
.icon-default-tello:before { content: '\e821'; } /* '' */
.icon-delete:before { content: '\e822'; } /* '' */
.icon-dice:before { content: '\e823'; } /* '' */
.icon-download:before { content: '\e824'; } /* '' */
.icon-emoji:before { content: '\e825'; } /* '' */
.icon-eye-hidden:before { content: '\e826'; } /* '' */
.icon-eye-shown:before { content: '\e827'; } /* '' */
.icon-facebook:before { content: '\e828'; } /* '' */
.icon-fire:before { content: '\e829'; } /* '' */
.icon-hint:before { content: '\e82a'; } /* '' */
.icon-image:before { content: '\e82b'; } /* '' */
.icon-imessage:before { content: '\e82c'; } /* '' */
.icon-inbox:before { content: '\e82d'; } /* '' */
.icon-info-message:before { content: '\e82e'; } /* '' */
.icon-info:before { content: '\e82f'; } /* '' */
.icon-instagram:before { content: '\e830'; } /* '' */
.icon-invite:before { content: '\e831'; } /* '' */
.icon-like:before { content: '\e832'; } /* '' */
.icon-liked:before { content: '\e833'; } /* '' */
.icon-listbullets:before { content: '\e835'; } /* '' */
.icon-location:before { content: '\e836'; } /* '' */
.icon-lock-1:before { content: '\e837'; } /* '' */
.icon-lock:before { content: '\e838'; } /* '' */
.icon-message:before { content: '\e839'; } /* '' */
.icon-messenger:before { content: '\e83a'; } /* '' */
.icon-network:before { content: '\e83b'; } /* '' */
.icon-no-response:before { content: '\e83c'; } /* '' */
.icon-paperclip:before { content: '\e83d'; } /* '' */
.icon-pin:before { content: '\e83e'; } /* '' */
.icon-plus:before { content: '\e83f'; } /* '' */
.icon-rounded-arrow:before { content: '\e840'; } /* '' */
.icon-save_image:before { content: '\e841'; } /* '' */
.icon-search:before { content: '\e842'; } /* '' */
.icon-send:before { content: '\e843'; } /* '' */
.icon-send-tell-1:before { content: '\e844'; } /* '' */
.icon-sent-tells:before { content: '\e846'; } /* '' */
.icon-share:before { content: '\e847'; } /* '' */
.icon-slides:before { content: '\e848'; } /* '' */
.icon-snapchat-2:before { content: '\e849'; } /* '' */
.icon-story:before { content: '\e84a'; } /* '' */
.icon-snapchat:before { content: '\e84b'; } /* '' */
.icon-swipe:before { content: '\e84c'; } /* '' */
.icon-text-message:before { content: '\e84d'; } /* '' */
.icon-text:before { content: '\e84e'; } /* '' */
.icon-timer:before { content: '\e850'; } /* '' */
.icon-twitter:before { content: '\e851'; } /* '' */
.icon-unlock:before { content: '\e852'; } /* '' */
.icon-warning:before { content: '\e853'; } /* '' */
.icon-whatsapp:before { content: '\e854'; } /* '' */
.icon-bell-1:before { content: '\e855'; } /* '' */
.icon-settings:before { content: '\e8a8'; } /* '' */
.icon-link:before { content: '\e8a9'; } /* '' */
.icon-threedots:before { content: '\e8aa'; } /* '' */
.icon-exclamation:before { content: '\e8ab'; } /* '' */
.icon-copy-3:before { content: '\e8ac'; } /* '' */
.icon-close-small:before { content: '\e8ad'; } /* '' */
